import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LoginRequest } from '../models/loginRequest';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LoginResponse } from '../models/loginResponse';
import { IUser } from '../models/IUser';
import { IUserProfile } from '../models/IUserProfile';

// http client options
// options: {
//   headers?: HttpHeaders | {[header: string]: string | string[]},
//   observe?: 'body' | 'events' | 'response',
//   params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
//   reportProgress?: boolean,
//   responseType?: 'arraybuffer'|'blob'|'json'|'text',
//   withCredentials?: boolean,
// }

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: IUser;

  // local version
  //baseUrlUser = 'https://localhost:5001/api/';

  // live version
  baseUrlUser = 'https://tecsa-user-api.azurewebsites.net/api/';

  httpOptions: {
    observe: 'response';
    responseType: 'json';
  };

  constructor(private http: HttpClient) { }

  // logs the user
  loginUser(loginRequest: LoginRequest): Observable<HttpResponse<LoginResponse>> {
    return this.http.post<LoginResponse>(`${this.baseUrlUser}login/loginPWA`, loginRequest, this.httpOptions)
      .pipe(catchError(this.handleError));
  };

  // sets user in memory so it can be retrieved from anywhere
  setUser(loginResponse: LoginResponse)
  {
    this.user = {
      id: loginResponse.userId,
      appId: loginResponse.appId,
      encAppId: loginResponse.encAppId,
      firstName: loginResponse.firstName,
      lastName: loginResponse.lastName,
      userName: loginResponse.userName,
      statusId: loginResponse.userStatusId,
      userRoles: loginResponse.userRoles?.split(','),
      monthlyLevel: loginResponse.monthlyLevel,
      onceOffLevel: loginResponse.onceOffLevel
    };
    //console.log('SetUser', loginResponse);
  }

  // returns user from memory
  getUser() {
    // return this.user;
    return new Observable(o => {
      o.next(this.user);
    });
  }

  // logs the user
  registerUser(registerRequest) {
    //console.log('Register Url', this.baseUrlUser);
    return this.http.post(`${this.baseUrlUser}register/registerPWA`, registerRequest);
  };

  // gets the user from the livelife middleware api
  // getUser(currentUser) {
  //   return this.http.post(`${this.baseUrl}getuserbyappid`, currentUser);
  // };

  // gets the netready user from the livelife middleware api (retrieved from the netready server via the neatready api)
  // getNetReadyUser(userName) {
  //   return this.http.get(`${this.baseUrl}getnetreadyuser/${userName}`);
  // };

  // gets the user profile from the middlware database
  getUserProfile(appId) {
    return this.http.get(`${this.baseUrlUser}userprofile/${appId}`);
  };

  // updates the user profile on the middleware database
  updateUserProfile(userProfile: IUserProfile) {
    return this.http.post(`${this.baseUrlUser}userprofile`, userProfile);
  };

  // updates the user profile on the middleware database
  changeNumber(changeNumberRequest) {
    return this.http.put(`${this.baseUrlUser}userprofile/changeNumber`, changeNumberRequest);
  };

  getDownlineUsers(userId) {
    return this.http.get(`${this.baseUrlUser}users/getdownlineusers/${userId}`);
  };

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
